import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import SupportIconImage from "../../images/support-icon.svg";
import ShieldIconImage from "../../images/shield-icon.svg";
import CustomizeIconImage from "../../images/customize-icon.svg";
import FastIconImage from "../../images/fast-icon.svg";
import ReliableIconImage from "../../images/reliable-icon.svg";
import SimpleIconImage from "../../images/simple-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({description = "APICove a valuable tool for professionals looking to streamline their integration processes and stay ahead in the competitive tech industry."}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: SimpleIconImage,
      title: "Curated Industry Standards",
      description: "APICove provides a meticulously curated list of industry standards for niche APIs, saving users from the hassle of sifting through endless options."
    },
    { imageSrc: SupportIconImage, title: "Comprehensive Services",
      description: "Alongside the curated standards, APICove offers a range of comprehensive services to facilitate seamless integration and enhance productivity." },
    { imageSrc: ShieldIconImage, title: "Continuous Updates and Support",
      description: "APICove is committed to providing continuous updates and support to ensure that users always have access to the latest standards and features, keeping them ahead of the curve." },
    { imageSrc: CustomizeIconImage, title: "User-Friendly Interface",
      description: "APICove boasts a user-friendly interface, making it easy for even non-technical users to navigate and utilize its features effectively." },
    { imageSrc: ReliableIconImage, title: "Simulators for Systems Integration",
      description: "APICove includes simulators specifically designed to streamline systems integration processes, allowing users to test and troubleshoot without disrupting their workflow." },
    { imageSrc: FastIconImage, title: "Compatibility with Cloud and On-Premise Solutions",
      description: "Whether you're working with cloud-based platforms or on-premise solutions, APICove is compatible with both, ensuring versatility and flexibility for users." }
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>Where Industry Standards Meet <span tw="text-primary-500">Innovation</span></Heading>        
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
